import { ControllerParams } from '@wix/yoshi-flow-editor';
import { toJS } from 'mobx';
import { AppStores } from './createStores';

export const toJsProps =
  (controllerParams: ControllerParams, stores: AppStores) => (): void => {
    const { setProps } = controllerParams.controllerConfig;

    const props = Object.entries(stores).reduce((acc: any, [k, store]) => {
      acc[k] = Object.entries(store.toProps()).reduce((_acc: any, [_k, _v]) => {
        _acc[_k] = toJS(_v);
        return _acc;
      }, {});
      return acc;
    }, {});

    setProps(props);
  };
