import { GiftCardCheckoutStore } from '../../../stores/GiftCardCheckoutStore';
import { GiftCardStore } from '../../../stores/GiftCardStore';
import { AppServices } from './initializeServices';

export type AppStores = {
  giftCardCheckoutStore: GiftCardCheckoutStore;
  giftCardStore: GiftCardStore;
};

export const createStores = async (
  appServices: AppServices,
): Promise<AppStores> => {
  const appStores: AppStores = {
    giftCardCheckoutStore: new GiftCardCheckoutStore(appServices),
    giftCardStore: new GiftCardStore(appServices),
  };

  await Promise.all([appStores.giftCardStore.init()]);

  return appStores;
};
