import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { GiftCardProductVariant } from '../../../../../types';
import { GiftCardController } from './controller/GiftCardController';
import { ITEM_TYPES } from '@wix/advanced-seo-utils';

const Controller: CreateControllerFn = async (controllerParams) => {
  const controller = await GiftCardController(controllerParams);

  controllerParams.controllerConfig.setProps({ fitToContentHeight: true });

  return {
    pageReady: async () => {
      await controller.init();

      const product = controller.getProduct();
      const wixCodeApi = controllerParams.flowAPI.controllerConfig.wixCodeApi;
      const currency = wixCodeApi.site.currency;

      const presetPrices = (product?.variants || [])
        .filter((variant) => variant.type === 'preset' && variant.active)
        .map((variant) => (variant as GiftCardProductVariant).price);

      const giftCardData = {
        pageUrl: `${wixCodeApi.location.baseUrl}/${wixCodeApi.location.path}`,
        cardName: product?.name,
        cardDesc: product?.description,
        image: [
          {
            imageWidth: product?.media.width,
            imageHeight: product?.media.height,
            imageUrl: product?.media.url,
            imageAlt: product?.media.alt_image,
          },
        ],
        price: {
          price: presetPrices,
          priceCurrency: currency,
        },
      };
      await wixCodeApi.seo.renderSEOTags({
        itemType: ITEM_TYPES.GIFT_CARD,
        itemData: {
          giftCard: giftCardData,
        },
      });
    },
    exports: () => controller.exports(),
  };
};

export default Controller;
