import { ControllerParams } from '@wix/yoshi-flow-editor';
import { autorun } from 'mobx';
import { AppStores } from './createStores';
import { toJsProps } from './toJsProps';

type ICreateUpdateComponent = (
  controllerParams: ControllerParams,
  stores: AppStores,
) => () => void;

export const createUpdateComponent: ICreateUpdateComponent = (
  controllerParams,
  stores,
) => {
  const updateComponent = toJsProps(controllerParams, stores);
  autorun(updateComponent);

  return updateComponent;
};
