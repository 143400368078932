import {
  CustomVariant,
  GiftCardProduct,
  GiftCardProductVariant,
} from '../../../../types';
import {
  CustomVariant as CustomVariantV1,
  GiftCardProduct as GiftCardProductV1,
  PresetVariant as PresetVariantV1,
} from '@wix/ambassador-gift-cards-v1-gift-card-product/types';

export const mapGiftCardProductV1ToGiftCardProduct = (
  instanceId: string,
  giftCardProduct: GiftCardProductV1,
): GiftCardProduct => {
  const variants = [
    ...(giftCardProduct.presetVariants
      ? mapPresetVariantsV1ToPresentVariants(giftCardProduct.presetVariants)
      : []),
    ...(giftCardProduct.customVariant
      ? [mapCustomVariantV1ToCustomVariant(giftCardProduct.customVariant)]
      : []),
  ].filter(Boolean);

  return {
    revision: giftCardProduct.revision,
    instance_id: instanceId,
    product_id: giftCardProduct.id ?? '',
    name: giftCardProduct.name ?? '',
    description: giftCardProduct.description ?? '',
    variants,
    media: giftCardProduct.image?.id
      ? {
          id: giftCardProduct.image?.id,
          url: giftCardProduct.image?.url ?? '',
          width: giftCardProduct.image?.width ?? 0,
          height: giftCardProduct.image?.height ?? 0,
          alt_image: giftCardProduct.image?.altText ?? '',
        }
      : { id: '', url: '', width: 0, height: 0, alt_image: '' },
    months_until_expired: giftCardProduct.relativeExpirationDate?.value ?? null,
    created_at: giftCardProduct.createdDate ?? '',
    updated_at: giftCardProduct.updatedDate ?? '',
    deleted_at: '',
  };
};

const mapCustomVariantV1ToCustomVariant = (
  variant: CustomVariantV1,
): CustomVariant => {
  return {
    min: variant.minValue ? Number(variant.minValue.amount) : null,
    max: variant.maxValue ? Number(variant.maxValue.amount) : null,
    media: variant.image?.id
      ? {
          id: variant.image?.id,
          url: variant.image?.url ?? '',
          width: variant.image?.width ?? 0,
          height: variant.image?.height ?? 0,
          alt_image: variant.image?.altText ?? '',
        }
      : undefined,
    type: 'custom',
    active: true,
  };
};

const mapPresetVariantsV1ToPresentVariants = (
  presetVariant: PresetVariantV1[],
): GiftCardProductVariant[] => {
  return presetVariant.filter(Boolean).map((variant: PresetVariantV1) => ({
    id: variant.id ?? '',
    price: Number(variant.price?.amount),
    value: Number(variant.value?.amount),
    media: variant.image?.id
      ? {
          id: variant.image?.id,
          url: variant.image?.url ?? '',
          width: variant.image?.width ?? 0,
          height: variant.image?.height ?? 0,
          alt_image: variant.image?.altText ?? '',
        }
      : undefined,
    type: 'preset',
    active: true,
  }));
};
